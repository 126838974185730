import React from 'react';
import './App.css' // Make sure this file imports Tailwind CSS
import logoImage from './Assets/OF-Transparent.png';
import ofImage from './Assets/Onlyfans-Logo.png';
import { FaTwitter } from "react-icons/fa";
import { FaTelegramPlane } from "react-icons/fa";
import wojak from './Assets/wojak.PNG';
import Dexscreener  from "./Assets/dex-screener.png";
import Dextools  from "./Assets/dt-logo-black.png";
import SolScan  from "./Assets/solscan.png";
import Jupiter  from "./Assets/jupiter-logo.png";
import Raydium  from "./Assets/Raydium-RAY-coin.webp";
import Xicon from './Assets/x-logo.png';
import Telegram from './Assets/tg-plane.png';
import candlePole from './Assets/IMG_3034.PNG'

const contractAddress = "0x1234567890abcdef"; // Replace with actual contract address

function copyToClipboard() {
  navigator.clipboard.writeText(contractAddress).then(() => {
    alert('Contract address copied to clipboard!');
  }, (err) => {
    console.error('Could not copy text: ', err);
  });
}

function App() {
  return (
    <div className="text-darkGray body-font">
      <header className="bg-lightBlue body-font">
        <div className="container mx-auto flex flex-wrap p-4 flex-col md:flex-row items-center">
          <a href="/" className="flex title-font font-medium items-center text-gray-900 mb-4 md:mb-0">
            <img src={ofImage} className='h-12 w-38 bg-white px-2 rounded-lg' alt="OnlyFansOnSol" />
          </a>
          {/* Navigation hidden on small screens and visible on medium and up */}
          <nav className="hidden md:flex md:ml-auto md:mr-auto flex-wrap items-center text-base justify-center font-bangers tracking-widest">
            <a href="#about" className="mr-5 text-darkBlue hover:text-white hover:bg-darkBlue border border-white bg-white px-3 py-2 rounded transition duration-300 ease-in-out transform hover:-translate-y-1">
              About
            </a>
            <a href="#how-to-buy" className="mr-5 text-darkBlue hover:text-white hover:bg-darkBlue border border-white bg-white px-3 py-2 rounded transition duration-300 ease-in-out transform hover:-translate-y-1">
              How to buy
            </a>

            <a href="#roadmap" className="mr-5 text-darkBlue hover:text-white hover:bg-darkBlue border border-white bg-white px-3 py-2 rounded transition duration-300 ease-in-out transform hover:-translate-y-1">
              Roadmap
            </a>

            <a href="#tokenomics" className="mr-5 text-darkBlue hover:text-white hover:bg-darkBlue border border-white bg-white px-3 py-2 rounded transition duration-300 ease-in-out transform hover:-translate-y-1">
              Tokenomics
            </a>
{/* 
            <a href="#our-partners" className="mr-5 text-darkBlue hover:text-white hover:bg-darkBlue border border-white bg-white px-3 py-2 rounded transition duration-300 ease-in-out transform hover:-translate-y-1">
              Meet the Models
            </a> */}
            <a href="#buy-now" className="text-darkBlue hover:text-white hover:bg-darkBlue border border-white bg-white px-3 py-2 rounded transition duration-300 ease-in-out transform hover:-translate-y-1">
              Buy
            </a>
            
          </nav>
          {/* Social media icons always visible, but layout adjusts on mobile */}
          <div className="flex justify-center md:justify-start w-full md:w-auto">
            <a href="https://twitter.com" className="mx-2 hover:text-white transition-transform duration-300 transform hover:scale-110">
              <FaTwitter className='text-white w-10 h-10'/>
            </a>
            <a href="https://telegram.org" className="mx-2 hover:text-white transition-transform duration-300 transform hover:scale-110">
              <FaTelegramPlane className='text-white w-10 h-10'/>
            </a>
          </div>
        </div>
      </header>

      <section id="about" className="text-white min-h-screen flex flex-col justify-center body-font">
  <div className="container mx-auto flex flex-col md:flex-row items-center justify-center flex-grow py-24 px-5">
    <div className="flex flex-col md:flex-row w-full">
      <div className="lg:flex-grow md:w-1/2 flex flex-col justify-between md:items-start md:text-left items-center text-center bg-lightBlue shadow-2xl px-6 py-8  md:p-12 rounded-lg font-bangers mx-4">
        <div>
          <h1 className="title-font mb-6 text-4xl sm:text-5xl md:text-6xl lg:text-6xl font-medium text-darkBlue tracking-wider" style={{
            textShadow: '2px 2px 0 #fff, -2px -2px 0 #fff, 2px -2px 0 #fff, -2px 2px 0 #fff'
          }}>
            Welcome to $ONLYFANS
          </h1>
          <p className="mb-8 text-sm sm:text-base md:text-lg lg:text-xl leading-relaxed tracking-wider text-white">
            ONLY FANS ON SOLANA IS THE HORNIEST MEME COIN ON THE BLOCKCHAIN. JOIN US IN OUR MISSION TO BRING THE ONLYFANS COMMUNITY TO THE SOLANA ECOSYSTEM.
          </p>
          <p className="mb-8 text-sm sm:text-base md:text-lg lg:text-xl leading-relaxed tracking-wider text-white flex items-center space-x-2">
            <strong>Contract Address:</strong> 
            <span className="bg-white text-darkBlue px-2 py-1 rounded">{contractAddress}</span>
            <button onClick={copyToClipboard} className="bg-darkBlue text-white px-2 py-1 rounded ml-2">Copy</button>
          </p>
        </div>
        <div className="mt-auto w-full">
          <div className="flex justify-center md:justify-center items-center w-full">
            <a href="/" className="hover:text-white transition-transform duration-300 transform hover:scale-110 mx-2">
              <img src={Xicon} className='w-8 h-8 md:w-12 md:h-12 lg:w-14 lg:h-14' alt="X icon" />
            </a>
            <a href="/" className="hover:text-white transition-transform duration-300 transform hover:scale-110 mx-2">
              <img src={Telegram} className='w-8 h-8 md:w-12 md:h-12 lg:w-14 lg:h-14' alt="Telegram" />
            </a>
            <a href="/" className="hover:text-white transition-transform duration-300 transform hover:scale-110 mx-2">
              <img src={Dexscreener} className='w-8 h-8 md:w-12 md:h-12 lg:w-14 lg:h-14 rounded-full' alt="DexScreener" />
            </a>
            <a href="/" className="hover:text-white transition-transform duration-300 transform hover:scale-110 mx-2">
              <img src={Dextools} className='w-8 h-8 md:w-12 md:h-12 lg:w-14 lg:h-14' alt="DexTools" />
            </a>
            <a href="/" className="hover:text-white transition-transform duration-300 transform hover:scale-110 mx-2">
              <img src={SolScan} className='w-8 h-8 md:w-12 md:h-12 lg:w-14 lg:h-14' alt="SolScan" />
            </a>
            <a href="/" className="hover:text-white transition-transform duration-300 transform hover:scale-110 mx-2">
              <img src={Jupiter} className='w-8 h-8 md:w-12 md:h-12 lg:w-14 lg:h-14' alt="Jupiter" />
            </a>
            <a href="/" className="hover:text-white transition-transform duration-300 transform hover:scale-110 mx-2">
              <img src={Raydium} className='w-8 h-8 md:w-14 md:h-14 lg:w-16 lg:h-16' alt="Raydium" />
            </a>
          </div>
        </div>
      </div>

      <div className="lg:flex-grow md:w-1/2 flex items-center justify-center mx-4 mt-8 md:mt-0">
        <img className="w-full h-auto max-w-full object-cover object-center rounded-lg" src={wojak} alt="OnlyFansOnSol Meme Coin" />
      </div>
    </div>
  </div>
</section>


      <section id="how-to-buy" className="bg-lightBlue min-h-screen flex items-center justify-center body-font">
        <div className="container w-full px-5 py-24 flex flex-col md:flex-row items-center justify-center flex-grow">
          <div className="bg-white shadow-2xl m-12 p-12 rounded-lg flex-grow flex flex-col md:items-start md:text-left items-center text-center w-full font-bangers">
            <h2 className="title-font sm:text-6xl text-5xl mb-6 font-medium text-lightBlue tracking-wider "
              style={{
                textShadow: '2px 2px 0 rgb(0 140 207), -2px -2px 0 rgb(0 140 207), 2px -2px 0 rgb(0 140 207), -2px 2px 0 rgb(0 140 207)'
              }}>
              How to Buy
            </h2>
            <div className="leading-relaxed lg:text-2xl sm:text-xl tracking-wider text-lightBlue space-y-6">
              <p>
                1. <strong>Create a Crypto Wallet:</strong> Download a wallet app like Phantom or Solflare on your mobile device or add the browser extension on your PC.
              </p>
              <p>
                2. <strong>Get some Solana (SOL):</strong> You can buy SOL through Phantom using Moonpay, transfer from another wallet, bridge from any blockchain, or purchase on Swissborg or KuCoin and transfer to your wallet.
              </p>
              <p>
                3. <strong>Swap SOL for $ONLYFANS:</strong> Go to Jupiter or Raydium, connect your wallet, and swap your SOL for $ONLYFANS.
              </p>
              <p>
                4. <strong>Contract Address:</strong> Make sure to paste the $ONLYFANS contract address in the receive box to ensure you are buying the correct coin.
                <span className="bg-lightBlue text-white ml-1 p-1 rounded">{contractAddress}</span>
                <button onClick={copyToClipboard} className="bg-darkBlue text-white p-1 rounded ml-2">Copy</button>
              </p>
              <p>
                5. <strong>Still Confused?</strong> Check out our video tutorial on how to buy $ONLYFANS. <span className="bg-lightBlue text-white px-2 py-1 rounded">[Placeholder for Video Tutorial]</span>
              </p>
            </div>
          </div>
        </div>
      </section>

      <section id="roadmap" className="text-lightBlue bg-white min-h-screen flex items-center justify-center body-font">
        <div className="container w-full px-5 py-24 flex flex-col md:flex-row text-center items-center justify-center flex-grow">
          <div className="bg-lightBlue shadow-2xl m-12 p-12 rounded-lg flex-grow flex flex-col md:items-start md:text-left items-center text-center w-full font-bangers">
            <h2 className="title-font sm:text-6xl text-5xl mb-6 font-medium text-white tracking-wider"
              style={{
                textShadow: '2px 2px 0 rgb(0 140 207), -2px -2px 0 rgb(0 140 207), 2px -2px 0 rgb(0 140 207), -2px 2px 0 rgb(0 140 207)'
              }}>
              Roadmap
            </h2>

            <div className="flex flex-col md:flex-row w-full">
              <div className="lg:flex-grow md:w-1/2 flex flex-col md:items-start md:text-left items-center text-center bg-white shadow-2xl p-12 rounded-lg font-bangers mx-4 mb-8">
                <h3 className="title-font sm:text-4xl text-3xl mb-6 font-medium text-lightBlue tracking-widest"
                  style={{
                    textShadow: '2px 2px 0 rgb(0 140 207), -2px -2px 0 rgb(0 140 207), 2px -2px 0 rgb(0 140 207), -2px 2px 0 rgb(0 140 207)'
                  }}>
                  Phase 1: Initial Launch
                </h3>
                <ul className="leading-relaxed lg:text-2xl sm:text-xl tracking-wider text-lightBlue">
                  <li>1. Collaboration with one of our OnlyFans models as the first KOL post-launch to drive initial awareness and engagement.</li>
                  <br />

                  <li>2. Solana Trending: Buy SOL trending following the initial KOL promotion for further exposure.</li>
                </ul>
              </div>
              <div className="lg:flex-grow md:w-1/2 flex flex-col md:items-start md:text-left items-center text-center bg-white shadow-2xl p-12 rounded-lg font-bangers mx-4 mb-8">
                <h3 className="title-font sm:text-4xl text-3xl mb-6 font-medium text-lightBlue tracking-widest"
                  style={{
                    textShadow: '2px 2px 0 rgb(0 140 207), -2px -2px 0 rgb(0 140 207), 2px -2px 0 rgb(0 140 207), -2px 2px 0 rgb(0 140 207)'
                  }}>
                  Phase 2: Sequential Partnerships
                </h3>
                <ul className="leading-relaxed lg:text-2xl sm:text-xl tracking-wider text-lightBlue">
                  <li>1. Rollout of partnerships with six additional OF model accounts.</li>
                    <br />
                  <li>2. Extended KOL Engagement: Plans to involve significant figures in the crypto space.</li>
                </ul>
              </div>
              <div className="lg:flex-grow md:w-1/2 flex flex-col md:items-start md:text-left items-center text-center bg-white shadow-2xl p-12 rounded-lg font-bangers mx-4 mb-8">
                <h3 className="title-font sm:text-4xl text-3xl mb-6 font-medium text-lightBlue tracking-widest"
                  style={{
                    textShadow: '2px 2px 0 rgb(0 140 207), -2px -2px 0 rgb(0 140 207), 2px -2px 0 rgb(0 140 207), -2px 2px 0 rgb(0 140 207)'
                  }}>
                  Phase 3: Model Partnerships
                </h3>
                <ul className="leading-relaxed lg:text-2xl sm:text-xl tracking-wider text-lightBlue">
                  <li>1. Collaborations with partnered OnlyFans models to offer exclusive discounts or special content to $ONLYFANS token holders.</li>
                  <br />

                  <li>2. Social Media Campaigns: Use of platforms like Twitter, Reddit, Telegram, and Instagram to generate buzz and foster a community around $ONLYFANS.</li>
                </ul>
              </div>
              <div className="lg:flex-grow md:w-1/2 flex flex-col md:items-start md:text-left items-center text-center bg-white shadow-2xl p-12 rounded-lg font-bangers mx-4 mb-8">
                <h3 className="title-font sm:text-4xl text-3xl mb-6 font-medium text-lightBlue tracking-widest"
                  style={{
                    textShadow: '2px 2px 0 rgb(0 140 207), -2px -2px 0 rgb(0 140 207), 2px -2px 0 rgb(0 140 207), -2px 2px 0 rgb(0 140 207)'
                  }}>
                  Phase 4: Community Engagement
                </h3>
                <ul className="leading-relaxed lg:text-2xl sm:text-xl tracking-wider text-lightBlue">
                  <li>1. Airdrops and Giveaways: Engage potential users by distributing a limited number of tokens through contests and airdrops.</li>
                  <br />

                  <li>2. Loyalty Rewards: Implementation of a loyalty system where token holders receive periodic rewards based on their holding duration and activity within the community. (Titty Tuesdays)</li>
                </ul>
              </div>
            </div>

          </div>
        </div>
      </section>

<section id="tokenomics" className="bg-lightBlue min-h-screen flex items-center justify-center body-font">
  <div className="container w-full px-5 py-24 flex flex-col items-center justify-center flex-grow">
    <div className="bg-white shadow-2xl m-12 p-12 rounded-lg flex-grow flex flex-col items-center text-center font-bangers w-full">
      <h2 className="title-font sm:text-6xl text-5xl mb-6 font-medium text-lightBlue tracking-wider"
        style={{
          textShadow: '2px 2px 0 rgb(0 140 207), -2px -2px 0 rgb(0 140 207), 2px -2px 0 rgb(0 140 207), -2px 2px 0 rgb(0 140 207)'
        }}>
        Tokenomics
      </h2>
      <div className="flex flex-col lg:flex-row w-full justify-between items-center">
        <div className="w-full lg:w-1/3 flex flex-col items-center text-center bg-lightBlue shadow-2xl p-8 lg:p-12 rounded-lg font-bangers m-4 lg:mb-0">
          <h3 className="title-font sm:text-4xl text-3xl mb-4 lg:mb-6 font-medium text-white tracking-widest"
            style={{
              textShadow: '2px 2px 0 rgb(0 140 207), -2px -2px 0 rgb(0 140 207), 2px -2px 0 rgb(0 140 207), -2px 2px 0 rgb(0 140 207)'
            }}>
            Sell Tax
          </h3>
          <p className="leading-relaxed text-3xl tracking-wider text-white">
            0%
          </p>
        </div>
        <div className="w-full lg:w-1/3 flex flex-col items-center text-center bg-lightBlue shadow-2xl p-8 lg:p-12 rounded-lg font-bangers m-4 lg:mb-0">
          <h3 className="title-font sm:text-4xl text-3xl mb-4 lg:mb-6 font-medium text-white tracking-widest"
            style={{
              textShadow: '2px 2px 0 rgb(0 140 207), -2px -2px 0 rgb(0 140 207), 2px -2px 0 rgb(0 140 207), -2px 2px 0 rgb(0 140 207)'
            }}>
            Supply
          </h3>
          <p className="leading-relaxed text-3xl tracking-wider text-white">
            1,000,000,000,000
          </p>
        </div>
        <div className="w-full lg:w-1/3 flex flex-col items-center text-center bg-lightBlue shadow-2xl p-8 lg:p-12 rounded-lg font-bangers m-4 lg:mb-0">
          <h3 className="title-font sm:text-4xl text-3xl mb-4 lg:mb-6 font-medium text-white tracking-widest"
            style={{
              textShadow: '2px 2px 0 rgb(0 140 207), -2px -2px 0 rgb(0 140 207), 2px -2px 0 rgb(0 140 207), -2px 2px 0 rgb(0 140 207)'
            }}>
            Buy Tax
          </h3>
          <p className="leading-relaxed text-3xl tracking-wider text-white">
            0%
          </p>
        </div>
      </div>
    </div>
  </div>
</section>


      {/* <section id="chart" className="bg-lightBlue text-lightBlue min-h-screen flex items-center justify-center body-font">
        <div className="container w-full px-5 py-24 flex flex-col md:flex-row items-center justify-center flex-grow">
          <div className="bg-white text-lightBlue shadow-2xl m-12 p-12 rounded-lg flex-grow flex flex-col items-center text-center font-bangers w-full">
            <h2 className="title-font sm:text-6xl text-5xl mb-6 font-mediu tracking-wider"
              style={{
                textShadow: '2px 2px 0 rgb(0 140 207), -2px -2px 0 rgb(0 140 207), 2px -2px 0 rgb(0 140 207), -2px 2px 0 rgb(0 140 207)'
              }}>
              Check our very horny chart!
            </h2>


            <div id="dexscreener-embed">
              <iframe src="https://dexscreener.com/solana/9DJrGmVqHxejzErYsc4LwCSaeAePXH1pCxUzLJVNfV77?embed=1&theme=dark&trades=0"></iframe>
            </div>
          
          </div>
        </div>
      </section> */}

      <section id="about" className="text-white min-h-screen flex flex-col justify-center body-font">
        <div className="container mx-auto flex flex-col md:flex-row items-center justify-center flex-grow py-24 px-5">
          <div className="flex flex-col md:flex-row w-full">
            <div className="lg:flex-grow md:w-1/2 flex flex-col justify-between md:items-start md:text-left items-center text-center bg-lightBlue shadow-2xl p-12 rounded-lg font-bangers mx-4">
              <div>
                <h1 className="title-font mb-6 text-4xl sm:text-5xl md:text-6xl lg:text-6xl font-medium text-darkBlue tracking-wider" style={{
                  textShadow: '2px 2px 0 #fff, -2px -2px 0 #fff, 2px -2px 0 #fff, -2px 2px 0 #fff'
                }}>
                  Get your $ONLYFANS now!
                </h1>
                <p className="mb-8 text-sm sm:text-base md:text-lg lg:text-xl leading-relaxed tracking-wider text-white">
                  $ONLYFANS is available on Jupiter and Raydium. Click the links below to buy now!
                </p>
                <p className="mb-8 text-sm sm:text-base md:text-lg lg:text-xl leading-relaxed tracking-wider text-white flex items-center space-x-2">
                  <strong>Contract Address:</strong> 
                  <span className="bg-white text-darkBlue px-2 py-1 rounded">{contractAddress}</span>
                  <button onClick={copyToClipboard} className="bg-darkBlue text-white px-2 py-1 rounded ml-2">Copy</button>
                </p>
              </div>
              <div className="mt-auto w-full">
                <div className="flex justify-center items-center w-full">
                  <a href="/" className="hover:text-white transition-transform duration-300 transform hover:scale-110">
                    <img src={Dexscreener} className='w-12 h-12 md:w-22 md:h-22 lg:w-32 lg:h-32 rounded-full mx-4' alt="DexScreener" />
                  </a>
                  <a href="/" className="hover:text-white transition-transform duration-300 transform hover:scale-110">
                    <img src={Dextools} className='w-12 h-12 md:w-22 md:h-22 lg:w-32 lg:h-32 mx-4' alt="DexTools" />
                  </a>
  
                  <a href="/" className="hover:text-white transition-transform duration-300 transform hover:scale-110">
                    <img src={Jupiter} className='w-12 h-12 md:w-22 md:h-22 lg:w-32 lg:h-32 mx-4' alt="Jupiter" />
                  </a>
                  <a href="/" className="hover:text-white transition-transform duration-300 transform hover:scale-110">
                    <img src={Raydium} className='w-12 h-12 md:w-24 md:h-22 lg:w-32 lg:h-32 mx-4' alt="Raydium" />
                  </a>
                </div>
              </div>
            </div>

            <div className="lg:flex-grow md:w-1/2 flex items-center justify-center mx-4 mt-8 md:mt-0">
              <img className="w-full h-auto max-w-full object-cover object-center rounded-lg" src={candlePole} alt="OnlyFansOnSol Meme Coin" />
            </div>
          </div>
        </div>
      </section>

      <section id="footer" className="text-white bg-darkBlue flex flex-col items-center justify-center body-font p-8 font-bangers">
        <p className="text-center text-xl tracking-wider mb-4">
          $ONLYFANS is a meme coin with no intrinsic value or expectation of financial return. The coin is for entertainment purposes only.
        </p>
        <p className="text-center text-xl tracking-wider mb-2">
          © 2024 by $ONLYFANS. All rights reserved!
        </p>
        <p className="text-center text-xl tracking-wider">
          Developed by Ruroc
        </p>
      </section>
    </div>
  );
}

export default App;
